<template>
  <div>
    <Spin size="large" fix v-if="spinShow"></Spin>
    <div v-if="userData">
      <div class="bg">
        <div class="w-b-92" style="margin: auto;">
          <div class="flex row items-center information-container justify-between">
            <div class="flex row">
              <img :src="userData.we_avatar" class="avatar" alt="头像">
              <div class="h-60 m-l-20">
                <h1 class="color-fff size-16 weight-bold">{{ userData.we_nickname }}</h1>
                <div class="flex row items-center">
                  <p class="color-fff size-14 m-t-8 phone">手机号: {{ userData.mobile }}</p>
                  <img src="@/assets/img/cop.png" class="copy copyPhone m-l-10" alt="复制图标"
                       v-clipboard='userData.mobile' v-clipboard:success="phoneSuccessHandler"
                       v-clipboard:error="phoneErrorHandler">
                </div>
              </div>
            </div>
            <div class="remark">
              <img src="@/assets/img/remark.png" class="remarkImg h-50 click-gesture" alt="备注" @click="notesClick">
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="flex row justify-center m-t-10">
          <p class="color-fff size-12">
            APP昵称：{{ userData.nickname }}
            <img src="@/assets/img/cop.png" class="copy m-l-10" alt="复制按钮" v-clipboard='userData.nickname'
                 v-clipboard:success="addNameSuccessHandler" v-clipboard:error="addNameErrorHandler">
          </p>
          <p class="color-fff size-12 m-l-45">
            消费金额：{{ userData.consume_price }}元
          </p>
        </div>
      </div>
      <!-- tab -->
      <ul class="flex row justify-around items-center">
        <li class="size-14 color-999 w-30 flex col items-center click-gesture" v-for="(item, index) in allTab"
            @click="num = index" :style="num == index ? 'color:#333333': ''"
            :class="num == index ? 'weight-bold': ''">{{ item }}
          <p class="m-t-7" :class="{line:num == index }"></p>
        </li>
      </ul>
      <div class="content">
        <div v-if="num == 0">
          <!-- 订单 -->
          <order></order>
        </div>
        <div v-if="num == 1">
          <!-- 添加 -->
          <increase></increase>
        </div>
        <div v-if="num == 2">
          <!-- 小组 -->
          <grouping></grouping>
        </div>
        <div v-if="num == 3">
          <!-- 学习 -->
          <study></study>
        </div>
<!--        <div v-if="num == 4">-->
<!--          &lt;!&ndash; 轨迹 &ndash;&gt;-->
<!--          <locus></locus>-->
<!--        </div>-->
        <div v-if="num==4">
          <!-- 信息 -->
          <information></information>
        </div>
      </div>
      <drawer title="添加备注" :display.sync="display" :width="drawerWidth" :height="drawerHeight">
        <addNotes :notes='notes'></addNotes>
      </drawer>
    </div>
    <div v-else>
      <div class="flex justify-center" v-if="appShow">
        <img src="@/assets/img//app.png" class="appImg" alt="该用户未注册app">
      </div>
    </div>
  </div>
</template>

<script>
// 组件
import drawer from '../../components/drawer.vue'

import addNotes from '../../components/remark/addNotes.vue'
import order from '../../components/order/order.vue'
import increase from '../../components/increase/increase.vue'
import grouping from '../../components/grouping/grouping.vue'
import study from '../../components/study/study.vue'
import locus from '../../components/locus/locus.vue'
import information from '../../components/information/information.vue'

import {
  getPageUserId,
  getUserInfo,
  getRemarkList
} from '@/api/code.js'

export default {
  name: 'Home',
  components: {
    addNotes,
    drawer,
    order,
    increase,
    grouping,
    study,
    locus,
    information
  },
  data() {
    return {
      userData: false,
      // allTab: ['订单', '添加', '小组', '学习', '轨迹', '信息'],
      allTab: ['订单', '添加', '小组', '学习', '信息'],
      // tab下标值
      num: 0,
      // 备注列表
      notes: [],
      // 抽屉
      activeName: '1',
      display: false,
      drawerWidth: '100%',
      drawerHeight: '80%',
      code: '',
      // loding
      spinShow: true,
      // userid
      wx_config_param_1: '',
      wx_config_param_0: '',
      // 暂未注册app判断
      appShow: false,
      // 悬浮参数
      timer: null,
      currentTop: 0
    }
  },
  async created() {
    let code = this.getQueryString('code')
    if (code) {
      this.code = code
      await this.getUserPermissions()
    } else {
      let appid = 'wwdd79e203f1f95c0f';
      let url = encodeURI(window.location.href)
      let state = '1231231'
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid +
          '&redirect_uri=' + url + '&response_type=code&scope=snsapi_base&state=' + state +
          '#wechat_redirect'
    }
  },
  methods: {
    //获取注入参数
    async getUserPermissions() {
      let that = this
      let url = (window.location.href).split('#')[0]
      getPageUserId({
        url
      }).then(async res => {

        that.wx_config_param_0 = res.data.app
        that.wx_config_param_1 = res.data.company
        // 微信
        let wx = window.wx
        //获取企业注入参数
        let enterprisePermissions = res.data.company
        // console.log('enterprisePermissions:' + JSON.stringify(enterprisePermissions))
        //注入企业权限start
        await wx.config({
          beta: true,
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来,如果是在PC上打开的话会直接打印log。
          appId: enterprisePermissions.corpid, //  必填，企业微信的corpID
          timestamp: enterprisePermissions.timestamp, // 必填，生成签名的时间戳
          nonceStr: enterprisePermissions.nonceStr, // 必填，生成签名的随机串
          signature: enterprisePermissions.signature, // 必填，签名
          jsApiList: ['getCurCorpGroupContact'], // 必填，需要使用的JS接口列表,可参考官方文档可授权的接口
          complete: function (success) {
            // console.log('wx.config' + JSON.stringify(success))
          }
        });
        //注入企业权限end

        //注入应用权限start
        await wx.ready(async _ => {
          //获取应用注入参数
          let appPermissions = that.wx_config_param_0
          // console.log('appPermissions:' + JSON.stringify(appPermissions))
          wx.agentConfig({
            corpid: appPermissions
                .corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
            agentid: appPermissions
                .agentid, // 必填，企业微信的应用id （e.g. 1000247）
            timestamp: appPermissions.timestamp, // 必填，生成签名的时间戳
            nonceStr: appPermissions.nonceStr, // 必填，生成签名的随机串
            signature: appPermissions
                .signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
            jsApiList: ['getCurExternalContact',
              'openAppManage'
            ], //必填，传入需要使用的接口名称
            success: function (success) {
              // console.log('企业注册成功：' + JSON.stringify(success))
              // 回调
              wx.invoke('getCurExternalContact', {}, (
                  result) => {
                // console.log('getCurExternalContact',result)
                if (result.err_msg ===
                    "getCurExternalContact:ok") {
                  sessionStorage.setItem(
                      'work_user_id', result
                          .userId);
                  that.getUserInfoFun()
                } else {
                  //错误处理
                  // alert(result.err_msg)
                  alert('该功能只针对外部学员')
                }
              });

              // wx.invoke('openAppManage', {}, function(res){
              // console.log('openAppManage:'+JSON.stringify(res))
              // })

            },
            fail: function (res) {
              if (res.errMsg.indexOf('function not exist') > -
                  1) {
                alert('版本过低请升级')
              }
            }
          });
        })
        //注入应用权限end

        //监听执行失败错误
        wx.error((res) => {
          console.log("微信错误", res);
        });
      })
    },
    //获取用户信息
    async getUserInfoFun() {
      let that = this
      let work_id = sessionStorage.getItem('work_user_id');
      let comUser = sessionStorage.getItem('company_user_info_data')
      that.$store.commit("setWorkId", work_id)
      if (comUser) {
        this.code = ''
      }
      if (work_id) {
        //利用获取到的union_id 获取用户详情
        getUserInfo({
          userid: work_id,
          code: this.code
        }).then(res => {
          that.spinShow = !that.spinShow
          //TODO 未注册APP
          if (res.code === 204) {
            that.userData = false
            that.appShow = true
          } else {
            sessionStorage.setItem('user_info_data', JSON.stringify(res.data.user))
            that.userData = res.data.user
            //外部联系人数据
            that.$store.commit("setUser", res.data.user);
            if (res.data.company_user) {
              //当前操作人数据
              sessionStorage.setItem('company_user_info_data', JSON.stringify(res.data
                  .company_user))
              that.$store.commit("setCompanyUser", res.data.company_user)
            }
          }
        })
      } else {
        that.spinShow = !that.spinShow
      }
    },

    //获取链接参数
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },

    // 备注
    notesClick() {
      this.display = !this.display
      let data = {
        user_id: this.$store.state.userData.id,
        page: 1,
        limit: 10
      }
      // 备注记录
      getRemarkList(data).then(res => {
        this.notes = res.data
      })
    },

    // 电话复制回调
    phoneSuccessHandler({
                          value,
                          event
                        }) {
      this.$Message.success({
        content: '复制成功',
      })
    },

    phoneErrorHandler({
                        value,
                        event
                      }) {
      this.$Message.error({
        content: '复制失败',
      })
    },

    // 用户名复制回调
    addNameSuccessHandler({
                            value,
                            event
                          }) {
      this.$Message.success({
        content: '复制成功',
      })
    },

    addNameErrorHandler({
                          value,
                          event
                        }) {
      this.$Message.error({
        content: '复制失败',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.remark {
  // position: fixed;
  // top: 6%;
  //   left: 81.5%;
  // z-index: 10;

  .remarkImg {
    width: 52px;
    height: 28px;
  }
}

.appImg {
  width: 207px;
  height: 219px;
}

.bg {
  background: url('../../assets/img/bg.png') no-repeat center center;
  background-size: 100% 100%;
  min-height: 185px;

  .information-container {
    padding: 37px 0 20px 0;

    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      margin-left: 22px;
    }
  }

  .copy {
    width: 10.78px;
    height: 10.66px;
  }

  .line {
    height: 1px;
    width: 85%;
    margin: auto;
    background-color: #8BC3F9;
  }
}

ul {
  padding-left: 0px !important;

  .line {
    width: 20px;
    height: 3px;
    background-color: #004EA2;
    border-radius: 2px;
  }
}

.content {
  margin-top: 20px;
}
</style>
