<template>
	<!-- 关键词搜索 -->
	<div>
		<div class="search flex row items-center color-666 size-12 h-40" v-for="(content, idx) in list" :key='idx'>
			<div class="w-b-50 text-center">
				<p>{{ content.remarks }}</p>
			</div>
			<div class="w-b-50 text-center">
				<p>{{ content.sum_number }}次</p>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'trackKeywords',
		props: {
			list: {
				type: Array,
				default: []
			}
		}
	}
</script>

<style scoped>
	.search {
		border-bottom: 1px solid rgba(238, 238, 238, 0.5);
	}
</style>