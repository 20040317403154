<template>
	<!-- 行动轨迹 -->
	<div class="m-b-60">
		<div class="line flex justify-center p-t-15" style="" v-for="(i, index) in list" :key="index">

			<div class="w-b-95">
				<div class="flex row items-center justify-between">
					<p class="color-333 size-14 weight-bold">{{ i.remarks }}</p>
				</div>
				<div class="flex row items-center justify-between m-t-10 color-666 size-12">
					<div>
						<p>课程停留总时长：{{ i.access_duration }}</p>
					</div>
					<div v-if="priceShow" class="flex row items-center">
						<p class="color-333 size-12" v-show="i.price">￥</p>
						<h1 class="color-333 size-14 weight-bold font-500">{{ i.price }}</h1>
					</div>
				</div>
				<div class="flex row justify-between color-999 size-12 m-t-10">
					<p class="time">上次访问时间：{{ i.create_times }}</p>
					<div v-if="stateShow" class="rim flex items-center justify-center"
						:class="i.is_buy == 1 ? 'not-purchased' : i.is_buy == 2 ? 'bought' : ''">
						<p class="size"
							:class="i.is_buy == 1 ? 'size-color-004' : i.is_buy == 2 ? 'size-color-F34' : ''">
							{{ i.is_buy == 1 ? '未购买' : i.is_buy == 2 ? '已购买' : '' }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import drawer from './drawer.vue'

	export default {
		name: 'actionTtacks',
		props: {
			list: {
				type: Array,
				default: () => []
			},
			frequencyShow: {
				type: Boolean,
				default: true
			},
			priceShow: {
				type: Boolean,
				default: false
			},
			stateShow: {
				type: Boolean,
				default: false
			}
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.bought {
		border: 1px solid #F3474D;
	}
	
	.not-purchased {
		border: 1px solid #004EA2;
	}
	
	.size-color-F34 {
		color: #F3474D
	}
	
	.size-color-004 {
		color: #004EA2
	}
	
	.line {
		border-bottom: 1px solid rgba(246, 246, 246, 1);
		padding-bottom: 15px;

		.rim {
			width: 38px;
			height: 16px;
			border-radius: 10px;

			.size {
				font-size: 9px;
				transform: scale(0.85);
			}
		}

	}
</style>
