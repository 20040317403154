import { render, staticRenderFns } from "./courseDetails.vue?vue&type=template&id=4bf42c94&scoped=true&"
import script from "./courseDetails.vue?vue&type=script&lang=js&"
export * from "./courseDetails.vue?vue&type=script&lang=js&"
import style0 from "./courseDetails.vue?vue&type=style&index=0&id=4bf42c94&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bf42c94",
  null
  
)

export default component.exports