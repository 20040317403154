<template>
	<!-- 轨迹tab -->
	<ul class="tab flex row m-t-20">
		<li class="crucial flex items-center justify-center click-gesture" 
		v-for="(i, index) in list" 
		:key="index"
		:style="idx == index ? 'color: #004EA2; backgroundColor: rgba(0, 78, 162, 0.15); borderRadius: 20px' : ''"
		@click="tabClick(index)">
			<p>{{ i }}</p>
		</li>
	</ul>
</template>

<script>
	 
	export default {
		name: "tarckTab",
		props: {
			list: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
				idx: 0
			}
		},
		methods: {
			tabClick(index){
				this.idx = index
				this.$EventBus.$emit('tabIdx', this.idx)
			}
 		}
	}
</script>

<style lang="scss" scoped>
	.tab {
		width: 91%;
		height: 40px;
		color: #BFBFBF;
		font-size: 14px;
		background: #F1F1F1;
		border-radius: 20px 20px 20px 20px;
		.crucial {
			width: 50%;
		}
	}
</style>