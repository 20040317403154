const state = {
	staff: [],
	groups: []
}

const mutations = {
	getStaff (state, val) {
		state.staff = val
	},
	getGroups (state, val) {
		state.groups = val
	}
}

export default {
	namespaced: true,
	state,
	mutations,
}
