<template>
	<div class="basic-title flex row items-center">
		<p class="long-block m-l-15"></p>
		<p class="color-333 size-14 m-l-10">{{ title }}</p>
	</div>
</template>

<script>
	export default {
		name: 'longBlock',
		props: {
			title: {
				type: String,
				default: ''
			}
		}
	}
</script>

<style lang="scss" scoped>
	.basic-title {
		height: 34px;
		background-color: #F8F8F8;
	
		.long-block {
			width: 4px;
			height: 15px;
			background: #004EA2;
		}
	}
</style>