const state = {
	order: []
}

const mutations = {
	getOrder (state, val) {
		state.order = val
	},
}

export default {
	namespaced: true,
	state,
	mutations,
}
