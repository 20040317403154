<template>
	<!-- 轨迹 -->
	<div class="m-b-60">
		<div class="track">
			<ul class="flex row">
				<li class="track-tab flex items-center justify-center m-r-15 click-gesture" v-for="(tab, tarckIndex) in trackTab"
					:key="tarckIndex"
					:style="tarckIdx == tarckIndex ? 'color: #004EA2; backgroundColor: rgba(0, 78, 162, 0.15);' : ''"
					@click="trackClick(tarckIndex)">
					<p>{{ tab }}</p>
				</li>
			</ul>
			<div class="track-content m-t-15">
				<!-- 轨迹公告 -->
				<div v-if="tarckIdx == 0">
					<div class="announcement">
						<div class="flex justify-center">
							<trackTab :list="tab"></trackTab>
						</div>
						<div class="announcement-content m-t-15" :class="announcementCrucial ? 'p-b-15' : ''">
							<!-- 关键词 -->
							<div v-show="announcementIdx == 0">
								<div v-if="announcementCrucial.length">
									<trackKeywords :list='announcementCrucial'></trackKeywords>
								</div>
								<div v-else class="flex justify-center">
									<img src="@/assets/img/nullData.png" alt="" class="w-162 h-190">
								</div>
							</div>
							<!-- 行为轨迹 -->
							<div v-show="announcementIdx == 1">
								<div v-if="announcementTrack.length">
									<announcement :list="announcementTrack"></announcement>
								</div>
								<div v-else class="flex justify-center">
									<img src="@/assets/img/nullData.png" alt="" class="w-162 h-190">
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 轨迹题库 -->
				<div v-if="tarckIdx == 1">
					<div class="question">
						<div class="flex justify-center">
							<trackTab :list="tab"></trackTab>
						</div>
						<div class="question-content m-t-15" :class="questionCrucial ? 'p-b-15' : ''">
							<!-- 关键词 -->
							<div v-show="questionIdx == 0">
								<div v-if="questionCrucial.length">
									<trackKeywords :list='questionCrucial'></trackKeywords >
								</div>
								<div v-else class="flex justify-center">
									<img src="@/assets/img/nullData.png" alt="" class="w-162 h-190">
								</div>
							</div>
							<!-- 行为轨迹 -->
							<div v-show="questionIdx == 1" class="m-t-20">
								<div v-if="questionTrack.length">
									<questionBank :list="questionTrack"></questionBank>
								</div>
								<div v-else class="flex justify-center">
									<img src="@/assets/img/nullData.png" alt="" class="w-162 h-190">
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 轨迹课程 -->
				<div v-if="tarckIdx == 2">
					<div class="course">
						<div class="flex justify-center">
							<trackTab :list="tab"></trackTab>
						</div>
						<div class="course-content m-t-15" :class="courseCrucial ? 'p-b-15' : ''">
							<!-- 关键词 -->
							<div v-show="courseIdx == 0">
								<div v-if="courseCrucial.length">
									<trackKeywords :list='courseCrucial'></trackKeywords>
								</div>
								<div v-else class="flex justify-center">
									<img src="@/assets/img/nullData.png" alt="" class="w-162 h-190">
								</div>
							</div>
							<!-- 行为轨迹 -->
							<div v-show="courseIdx == 1" class="m-t-20">
								<div v-if="courseTrack.length">
									<course :list="courseTrack"></course>
								</div>
								<div v-else class="flex justify-center">
									<img src="@/assets/img/nullData.png" alt="" class="w-162 h-190">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			</mescroll-vue>
		</div>
	</div>
</template>

<script>
	import trackTab from '../trackTab.vue'
	import trackKeywords from '../trackKeywords.vue'
	import drawer from '../drawer.vue'

	// 公告
	import announcement from './announcement.vue'
	// 题库
	import questionBank from './questionBank.vue'
	// 课程
	import course from './course.vue'

	import {
		getUserTrack
	} from '../../api/code.js'

	export default {
		name: 'locus',
		props: {},
		components: {
			trackTab,
			trackKeywords,
			drawer,
			announcement,
			questionBank,
			course,
		},
		data() {
			return {
				// 《轨迹》
				tarckIdx: 0,
				trackTab: ['公告', '题库', '课程'],
				tab: ['关键词搜索', '行为轨迹'],
				// 公告
				announcementIdx: 0,
				// 题库
				questionIdx: 0,
				// 课程
				courseIdx: 0,
			}
		},
		computed: {
			// 公告信息
			announcementCrucial() {
				return this.$store.state.locus.announcementCrucial
			},
			announcementTrack() {
				return this.$store.state.locus.announcementTrack
			},
			// 题库信息
			questionCrucial() {
				return this.$store.state.locus.questionCrucial
			},
			questionTrack() {
				return this.$store.state.locus.questionTrack
			},
			// 课程信息
			courseCrucial() {
				return this.$store.state.locus.courseCrucial
			},
			courseTrack() {
				return this.$store.state.locus.courseTrack
			},
		},
		created() {
			if (this.$store.state.locus.announcementCrucial.length == 0 &&  this.$store.state.locus.announcementTrack.length == 0) {
				this.getData()
			}
		},
		mounted() {
			this.$EventBus.$on('tabIdx', (idx) => {
				// 轨迹-公告切换
				this.announcementIdx = idx
				// 轨迹-题库切换
				this.questionIdx = idx
				// 轨迹-课程切换
				this.courseIdx = idx
			})
		},
		methods: {
			trackClick(tarckIndex) {
				this.tarckIdx = tarckIndex
				// 判断数据是否为空，空请求api
				if (this.tarckIdx == 0 && this.$store.state.locus.announcementCrucial.length == 0 && this.$store.state.locus.announcementTrack.length == 0) {
					this.getData()
				} else if (this.tarckIdx == 1 && this.$store.state.locus.questionTrack.length == 0 && this.$store.state.locus.questionCrucial == 0) {
					this.getQuestion()
				} else if (this.tarckIdx == 2 && this.$store.state.locus.courseTrack.length == 0 && this.$store.state.locus.courseCrucial.length == 0) {
					this.getCours()
				}
			},
			
			getData() {
				let announcement = {
					user_id: this.$store.state.userData.id,
					type: 1,
					page: 1,
					limit: 10
				}
				// 轨迹列表
				getUserTrack(announcement).then(res => {
					let dataRes = res.data
					this.$store.commit('locus/getAnnouncementCrucial', dataRes.search)
					this.$store.commit('locus/getAnnouncementTrack', dataRes.list)
				})
			},
			
			getQuestion() {
				let question = {
					user_id: this.$store.state.userData.id,
					type: 2,
					page: 1,
					limit: 10
				}
				getUserTrack(question).then(res => {
					let dataRes = res.data
					this.$store.commit('locus/getQuestionCrucial', dataRes.search)
					this.$store.commit('locus/getQuestionTrack', dataRes.list)
				})
			},
			
			getCours() {
				let course = {
					user_id: this.$store.state.userData.id,
					type: 3,
					page: 1,
					limit: 10
				}
				getUserTrack(course).then(res => {
					let dataRes = res.data
					this.$store.commit('locus/getCourseCrucial', dataRes.search)
					this.$store.commit('locus/getCourseTrack', dataRes.list)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.track {
		width: 92%;
		margin: auto;

		.track-tab {
			width: 68px;
			height: 30px;
			color: #BFBFBF;
			font-size: 14px;
			background: #F1F1F1;
			border-radius: 14px;
		}

		.track-content {

			.mescroll {
				position: fixed;
				// top: 345px;
				width: 92%;
				height: 50%;
				margin: auto;
				background-color: #ffffff;
			}

			.announcement {
				width: 100%;
				background: #FFFFFF;
				box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
				border-radius: 3px;
			}

			.question {
				width: 100%;
				background: #FFFFFF;
				box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
				border-radius: 3px;
			}

			.course {
				width: 100%;
				background: #FFFFFF;
				box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
				border-radius: 3px;
			}
		}
	}
</style>
