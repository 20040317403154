<template>
	<!-- 分组列表 -->
	<div class="m-b-60">
		<div>
			<mescroll-vue ref="mescroll" :down='mescrollDown' :up='mescrollUp' @init="mescrollInit" class="mescroll">
				<div v-if="grouping.length" class="grouping">
					<div class="staff flex col items-center">
						<div class="flex row items-center justify-between w-b-95 m-t-15">
							<div class="flex row items-center">
								<img src="@/assets/img/add.png" class="addImg">
								<p class="color-333 size-14 weight-bold m-l-5">ta已加入小组</p>
							</div>
							<div>
								<p @click="addGroupClick" class="color-004 size-12 click-gesture">添加小组</p>
							</div>
						</div>
						<div class="line m-t-7"></div>
						<!-- 已有的分组列表 -->
						<div class="member-list m-t-10 flex row w-b-95" v-for="(i, index) in grouping" :key="index">
							<div>
								<img src="@/assets/img/wechat.png" class="avatar" alt="">
							</div>
							<div class="information click-gesture" @click="groupDetailsClick(i.group_id)">
								<div class="flex row items-center justify-between">
									<p class="color-333 size-12 weight-bold">{{ i.name}}</p>
									<div>
										<img src="@/assets/img/youjiantou.png" class="icon" alt="">
									</div>
								</div>
								<div class="m-t-5 m-f-l-10 flex row">
									<span class="color-999 size-11 m-r-10">邀请人：{{ i.admin_name }}<span
											class="color-999 size-11 m-l-15">加入时间：{{ i.create_times }}</span></span>
								</div>
								<p class="line m-t-15"></p>
							</div>
						</div>
					</div>
				</div>
				<!-- 分组列表为空时 -->
				<div v-else>
					<div class="add-group-img flex col items-center" v-if="spinShow">
						<div class="m-t-23">
							<img src="@/assets/img/null.png" class="img" alt="">
						</div>
						<div class="btn flex justify-center items-center m-t-40 click-gesture" @click="addGroupClick">
							<p class="color-fff size-12">+ 为他/她添加小组</p>
						</div>
					</div>
				</div>
			</mescroll-vue>
		</div>

		<!-- 添加列表抽屉 -->
		<drawer title="添加分组" :display.sync="display" :width="drawerWidth" :height="drawerHeight">
			<div>
				<div class="add-group">
					<div class="m-b-60" :class="{'m-b-120' : radioList}">
						<form class="search flex justify-between items-center">
							<div class="search-bg flex justify-center items-center">
								<div class="w-b-94 flex items-center">
									<img src="@/assets/img/search.png" class="img" alt="搜索">
										<input type="text" placeholder="输入关键词筛选分组" v-model="keyword" @keyup.enter='searchClick'
										class="search-input w-b-100 m-l-10 color-333 size-14">
								</div>
							</div>
							<div class="color-333 size-14">
								<button type="button" class="color-333 size-14 click-gesture"
									@click="searchClick">搜索</button>
							</div>
						</form>
						<div class="m-t-5">
							<div class="content m-t-15" v-for="(item, index) in group" :key="index">
								<div class="flex justify-center">
									<div class="w-b-95 flex items-center justify-between">
										<div class="information click-gesture" @click="addGroupDetailsClick(item.id)">
											<div class="flex row items-center">
												<img src="@/assets/img/member.png" class="member" alt="会员">
												<p class="color-333 size-14 weight-bold m-l-5">{{ item.name }}</p>
											</div>
											<p class="color-666 size-12 m-t-15 m-l-20">创建人：{{ item.admin_name }}</p>
										</div>
										<div>
											<input :id="index" type="radio" class="input-radio click-gesture"
												@change="retainRecord(item.id)" @click="controlSingel($event, item.id)">
									</div>
										</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="footer-bg flex items-center justify-center" v-show="radioList">
						<div class="click-gesture" @click="addClick">
							<img src="@/assets/img/addto.png" class="add-to" alt="">
							<span class="color-004 size-14 m-l-5">添加小组（已选择{{ radioList }}个）</span>
						</div>
					</div>
				</div>
			</div>
		</drawer>

		<!-- 分组详情 -->
		<drawer title="小组详情" :display.sync="groupDetailsDisplay" :width="drawerWidth" :height="drawerHeight">
			<groupDetails :groupDetails='groupDetails'></groupDetails>
		</drawer>
	</div>
</template>

<script>
	import list from '../list.vue'
	import drawer from '../drawer.vue'
	import groupDetails from './groupDetails.vue'
	import MescrollVue from 'mescroll.js/mescroll.vue'

	import {
		getAddGroup,
		getGroupList,
		getAddedSuccessfully,
		getGroupEquity
	} from '../../api/code.js'

	export default {
		name: 'grouping',
		props: {},
		components: {
			list,
			drawer,
			groupDetails,
			MescrollVue
		},
		data() {
			return {
				// 《分组》
				imgUrl: require('@/assets/img/grouping.png'),
				// 抽屉1
				activeName: '1',
				display: false,
				// 抽屉2
				groupDetailsDisplay: false,
				drawerWidth: '100%',
				drawerHeight: '80%',
				// 添加分组
				// 搜索框
				keyword: '',
				// 添加数量
				radioList: 0,
				// 添加分组列表
				group: [],
				// 分组添加请求id
				groupId: [],
				// 分组详情
				groupDetails: [],
				// 下拉刷新
				mescroll: null, // mescroll实例对象
				mescrollDown: {
					auto: false,
					callback:function ( mescroll ) {
						mescroll.resetUpScroll();
					},
					autoShowLoading: true,
					afterLoading: function(mescroll) {
						return 2000
					},
					offset: 40
				},
				mescrollUp: {
					// 上拉回调
					callback: this.upCallback,
					auto: false,
					page: {
						num: 1,
						size: 10
					},
					noMoreSize: 10,
					toTop: {
						// 回到顶部按钮的图片路径,支持网络图
						src: 'http://www.mescroll.com/img/mescroll-totop.png'
					},
					lazyLoad: {
						use: true // 是否开启懒加载,默认false
					}
				},
				// loading
				spinShow: false,
			}
		},
		computed: {
			// 已有分组列表
			grouping() {
				return this.$store.state.grouping.grouping
			}
		},
		created() {
			if (this.$store.state.grouping.grouping.length == 0) {
				this.getDate()
				setTimeout(_ => {
					this.spinShow = true
				},1000)
			}
		},
		watch: {
			// 监听搜索框
			keyword() {
				if (this.keyword.length == 0) {
					getAddGroup({
						user_id: this.$store.state.userData.id
					}).then(res => {
						this.group = res.data
					})
				}
			}
		},
		methods: {
			// radio取消
			controlSingel($event, id) {
				let _this = this;
				window.setTimeout(() => {
					if (!this.changed) {
						$event.target.checked = false
						this.radioList -= 1
						this.groupId.splice(this.groupId.indexOf(id), 1)
					}
					_this.changed = false;
				}, 0);
			},

			// radio选中
			retainRecord(id) {
				this.changed = true;
				this.radioList += 1
				this.groupId.push(id)
			},
			
			// mescroll组件初始化的回调,可获取到mescroll对象
			mescrollInit(mescroll) {
				this.mescroll = mescroll
			},
			
			// 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数(可配置),默认10
			upCallback(page, mescroll) {
				// 联网请求
				let data = {
					user_id: this.$store.state.userData.id,
					admin_id: this.$store.state.companyUserData.id
				}
				getGroupList(data).then((response) => {
					// 请求的列表数据
					let arr = response.data.list
					// 如果是第一页需手动置空列表
					if ( this.$store.state.grouping.grouping.length < 10) this.$store.state.grouping.grouping = []
					// 把请求到的数据添加到列表
					this.$store.state.grouping.grouping = this.$store.state.grouping.grouping.concat(arr)
					// 数据渲染成功后,隐藏下拉刷新的状态
					this.$nextTick(() => {
						mescroll.endSuccess(arr.length)
					})
				}).catch((e) => {
					// 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
					mescroll.endErr()
				})
			},

			// 搜索
			searchClick() {
				let data = {
					user_id: this.$store.state.userData.id,
					admin_id: this.$store.state.companyUserData.id,
					name: this.keyword
				}
				getAddGroup(data).then(res => {
					this.group = res.data
				})
			},

			getDate() {
				// 已有分组列表
				getGroupList({
					user_id: this.$store.state.userData.id,
					admin_id: this.$store.state.companyUserData.id
				}).then(res => {
					this.$store.commit('grouping/getGrouping', res.data.list)
				})
			},

			addGroupClick() {
				this.display = !this.display
				// 添加分组列表
				getAddGroup({
					user_id: this.$store.state.userData.id,
					admin_id: this.$store.state.companyUserData.id
				}).then(res => {
					this.group = res.data
				})
			},
			
			// 添加列表的分组详情
			addGroupDetailsClick(groupId) {
				this.groupDetailsDisplay = !this.groupDetailsDisplay
				// 分组权益
				getGroupEquity({
					group_id: groupId
				}).then(res => {
					this.groupDetails = res.data
				})
			},
			
			// 已有分组列表的分组详情
			groupDetailsClick(groupId) {
				this.groupDetailsDisplay = !this.groupDetailsDisplay
				// 分组权益
				getGroupEquity({
					user_id: this.$store.state.userData.id,
					group_id: groupId
				}).then(res => {
					this.groupDetails = res.data
				})
			},

			addClick() {
				const data = {
					user_id: this.$store.state.userData.id,
					group_id: this.groupId,
					admin_id: this.$store.state.companyUserData.id,
					admin_name: this.$store.state.companyUserData.username,
					admin_type: 2
				}
				// 添加分组成功
				getAddedSuccessfully(data).then(res => {
					this.$Message.success(res.msg)
					this.group = []
					this.radioList = 0
					this.getDate()
					this.addGroupClick()
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.staff {
		.addImg {
			width: 11.41px;
			height: 12.62px;
		}

		.line {
			width: 100%;
			height: 1px;
			background-color: #F6F6F6;
		}

		.avatar {
			width: 40px;
			height: 40px;
			border-radius: 30px;
		}

		.member-list {
			.information {
				margin-left: 18px;
				width: 85%;

				.icon {
					width: 5.74px;
					height: 10.3px;
				}
			}
		}
	}

	.add-group {
		width: 92%;
		margin: auto;

		.search {
			margin-top: 20px;

			.search-bg {
				width: 85%;
				height: 30px;
				background-color: #eeeeee;
				border-radius: 15px;
			}

			.img {
				width: 12.41px;
				height: 13.08px;
			}

			.search-input::placeholder {
				font-size: 14px;
				color: #999999;
			}
		}

		.content {
			background-color: #ffffff;
			box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
			border-radius: 3px 3px 0px 0px;
			padding: 18px 0 18px 0;

			.information {
				.member {
					width: 17.27px;
					height: 15.18px;
				}
			}

			.input-radio:checked {
				background: #004ea2;
			}

			.input-radio {
				-webkit-appearance: none;
				-moz-appearance: none;
				outline: none;
				width: 20px;
				height: 20px;
				background-color: #ffffff;
				border: solid 1px #dddddd;
				border-radius: 50%;
				margin: 0;
				padding: 0;
				position: relative;
				display: inline-block;
				/*文字对齐方式*/
				vertical-align: top;
				transition: background-color ease 0.1s;
			}

			.input-radio:checked::after {
				content: '';
				top: 3px;
				left: 4px;
				position: absolute;
				border: #fff solid 2px;
				border-top: none;
				border-right: none;
				height: 6px;
				width: 10px;
				transform: rotate(-45deg);
			}
		}
	}

	.footer-bg {
		position: fixed;
		width: 100%;
		height: 55px;
		background-color: #ffffff;
		// top: 92%;
		bottom: 0;
		z-index: 999;

		.add-to {
			width: 12px;
			height: 12px;
		}
	}

	.grouping {
		width: 92%;
		margin: auto;
		background-color: #ffffff;
		box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1000);
		border-radius: 6px;
	}

	.add-group-img {
		.img {
			width: 163px;
			height: 174.63px;
		}

		.btn {
			width: 140px;
			height: 32px;
			background-color: #004EA2;
			border-radius: 16px;
		}
	}
</style>
