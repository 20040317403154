import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import order from './modules/order'
import increase from './modules/increase'
import grouping from './modules/grouping'
import study from './modules/study'
import locus from './modules/locus'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		// user信息
    userData: {},
		// 操作人信息
    companyUserData: {},
		// workID
		workId: {},
  },
  mutations: {
    //外部联系人数据
    setUser(state, data) {
      state.userData = data
      // console.log("store_userData: " + JSON.stringify(data))
    },
    //当前操作人数据
    setCompanyUser(state, data) {
      state.companyUserData = data
      // console.log("store_companyUserData: " + JSON.stringify(data))
    },
		// wordID
		setWorkId(state, data) {
		  state.workId = data
		  // console.log("store_WorkId: " + JSON.stringify(data))
		},
  },
  actions: {},
  modules: {
		order,
		increase,
		grouping,
		study,
		locus
	},
  getters
})
