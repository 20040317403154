<template>
	<div id="app">
		<div class="main">
			<router-view />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'App',
		data: () => ({
			
		}),
		mounted() {
	
		}
	}
</script>

<style>
	* {
		margin: 0;
		padding: 0;
	}

	#app {
		background-color: #F8F9FB;
		height: 100vh;
		overflow: auto;
	}
</style>
