const state = {
	// 公告
	announcementCrucial: [],
	announcementTrack: [],
	// 题库
	questionCrucial: [],
	questionTrack: [],
	// 课程
	courseCrucial: [],
	courseTrack: [],
}

const mutations = {
	getAnnouncementCrucial (state, val) {
		state.announcementCrucial = val
	},
	getAnnouncementTrack (state, val) {
		state.announcementTrack = val
	},
	getQuestionCrucial (state, val) {
		state.questionCrucial = val
	},
	getQuestionTrack (state, val) {
		state.questionTrack = val
	},
	getCourseCrucial (state, val) {
		state.courseCrucial = val
	},
	getCourseTrack (state, val) {
		state.courseTrack = val
	},
}

export default {
	namespaced: true,
	state,
	mutations,
}
