import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 公共样式
import './assets/css/common.css'

// iview库
import iView from 'iview';
import 'iview/dist/styles/iview.css';
Vue.use(iView);

// message头部距离调整
Vue.prototype.$Message.config({
  top: 240,
	duration: 3,
	closable: false
});

// 复制
import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

// 事件中心
Vue.prototype.$EventBus = new Vue()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
