<template>
	<!-- ta加入等列表 -->
	<div class="staff flex col items-center">
		<div class="flex row items-center justify-between w-b-95 m-t-15">
			<div class="flex row items-center">
				<img src="../assets/img/add.png" class="addImg">
				<p class="color-333 size-14 weight-bold m-l-5">{{ title }}</p>
			</div>
			<div>
				<p v-if="numShow" class="color-004 size-12">{{ num }}个</p>
			</div>
		</div>
		<div class="line m-t-7"></div>
		<div class="member-list m-t-10 flex row w-b-95" v-for="(i, index) in list" :key="index">
			<div>
				<img :src="i.avatar ? i.avatar : avaterImg" class="avatar" alt="">
			</div>
			<div class="information">
				<div class="flex row items-center justify-between">
					<p class="color-333 size-12 weight-bold">{{ i.name ? i.name : i.username }}</p>
				</div>
				<div class="m-t-5 m-f-l-7 flex row">
					<p class="color-999 size-11 m-r-10" v-if="nickshow">{{ nick }}：{{ i.nickName ? i.nickName : i.owner}}</p>
					<p class="color-999 size-11">{{ timeName }}：{{ i.create_times ? i.create_times : i.join_time ? i.join_time : i.create_time }}</p>
				</div>
				<p class="line m-t-15"></p>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default {
		name: 'list',
		props: {
			list: {
				type: Array,
				default: () => []
			},
			title: {
				type: String,
				default: ''
			},
			numShow: {
				type: Boolean,
				default: true
			},
			nick: {
				type: String,
				default: ''
			},
			timeName: {
				type: String,
				default: ''
			},
			nickshow: {
				type: Boolean,
				default: false
			},
		},

		data() {
			return {
				// 群组头像
				avaterImg: require('@/assets/img/wechat.png'),
			}
		},
		computed: {
			// 群组和员工的个数
			num() {
				return this.list.length
			}
		},
		methods: {
		
		}
	}
</script>

<style lang="scss" scoped>
	.staff {
		.addImg {
			width: 11.41px;
			height: 12.62px;
		}

		.line {
			width: 100%;
			height: 1px;
			background-color: #F6F6F6;
		}

		.avatar {
			width: 40px;
			height: 40px;
			border-radius: 30px;
		}

		.member-list {
			.information {
				margin-left: 18px;
				width: 85%;

				.icon {
					width: 5.74px;
					height: 10.3px;
				}
			}
		}
	}
</style>
