<template>
	<div class="m-b-60">
		<div class="flex justify-center m-t-15">
			<div class="w-b-94">
				<div class="input-box flex justify-center">
					<div class="w-b-95 m-t-10 m-b-10">
						<textarea v-model="dataInfo" placeholder="补充备注，有助于更好的处理" maxlength="200" @input="descInput"
							class="w-b-100 h-50 color-333 size-14" /></textarea>
						<p class="color-ccc size-13 text-right ">{{wordCount}}/200</p>
					</div>
				</div>
				<div class="m-t-20">
					<div class="input-btn flex justify-center items-center click-gesture" @click="addClick">
						<p class="color-fff size-16 weight-bold">添加备注</p>
					</div>
				</div>
				<div class="notes-record m-t-30">
					<h1 class="color-333 size-18 weight-bold">备注记录</h1>
					<div class="flex row m-l-15 m-t-15">
						<div class="graphics" v-if="notes.length">
							<p class="line"></p>
							<p class="member member-one"></p>
							<p class="member member-two"></p>
							<p class="member member-three"></p>
						</div>
						<div class="remark-details m-l-20">
							<div class="m-b-20" v-for="(notes, index) in notes" :key="index">
								<p class="color-999 size-11">{{ notes.create_times }}</p>
								<div class="flex row items-center m-t-10">
									<img src="@/assets/img/avatar.png" class="avatar" alt="">
									<div class="m-l-15">
										<p class="color-333 size-12 weight-bold">{{ notes.admin_name }}</p>
										<div class="remarks text-center m-t-6">
											<p class="color-004 size-11">{{ notes.content }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	import {getRemarkList, getAddRemark} from '../../api/code.js'
	
	export default {
		name: 'detail',
		components: {},
		props: {
			notes: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
				// notes: [],
				wordCount: 0,
				dataInfo: ''
			}
		},
		methods: {
			// input输入长度
			descInput() {
				let txtVal = this.dataInfo.length
				this.wordCount = txtVal;
			},
			
			getData() {
				let data = {
					user_id: this.$store.state.userData.id,
					page: 1,
					limit: 10
				}
				// 备注记录
				getRemarkList(data).then(res => {
					this.notes = res.data
				})
			},
			
			addClick() {
				let data = {
					user_id: this.$store.state.userData.id,
					type: 2,
					admin_id: this.$store.state.companyUserData.id,
					admin_name: this.$store.state.companyUserData.username,
					content: this.dataInfo
				}
				// 添加备注
				getAddRemark(data).then(res => {
					this.$Message.success({
						content: res.msg,
					})
					this.dataInfo = ''
					this.getData()
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	textarea {
		resize: none;
		border: 0;
		outline: none
	}

	.input-box {
		width: 100%;
		height: 88px;
		background-color: rgba(238, 238, 238, 0.5);
		border-radius: 3px;
	}

	.input-btn {
		width: 100%;
		height: 45px;
		background-color: #004ea2;
		border-radius: 3px;
	}

	.notes-record {
		.line {
			width: 1px;
			height: 334px;
			background: #D5D5D5;
		}

		.member {
			width: 5px;
			height: 5px;
			background: #004EA2;
			border-radius: 30px;
		}

		.member-one {
			position: relative;
			bottom: 92%;
			right: 2px;
		}

		.member-two {
			position: relative;
			bottom: 62%;
			right: 2px;
		}

		.member-three {
			position: relative;
			bottom: 32%;
			right: 2px;
		}

		.remark-details {
			width: 100%;
			height: 334px;
			overflow: scroll;
			margin-top: 6px;

			.avatar {
				width: 40px;
				height: 40px;
				border-radius: 30px;
			}

			.remarks {
				background-color: rgba(0, 78, 162, 0.1);
				border-radius: 3px;
				padding: 10px;
			}
		}
	}
</style>
