<template>
	<div>
		<div class="course-bg">
			<div class="course-information m-t-15 flex items-center justify-center">
				<span class="color-333 size-14"><img src="@/assets/img/views.png" class='views m-r-5' alt="">访问次数：<span class="color-004">{{ number }}次</span></span>
				<span class="color-333 size-14 m-l-50">课程停留时长：<span class="color-004">{{ duration }}秒</span></span>
			</div>
			<div class="course-content">
				<div class="m-t-15">
					<actionTracks :list="course" :priceShow="true" :stateShow="true" :frequencyShow='false'>
					</actionTracks>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import actionTracks from "../../components/actionTracks.vue"
	

	export default {
		name: 'courseDetails',
		components: {
			actionTracks
		},
		props: {
			duration: {
				type: Number,
				default: 0
			},
			number: {
				type: Number,
				default: 0
			},
			course: {
				type: [Array, Object],
			}
		},
		data() {
			return {

			}
		},
		methods:{
		}
	}
</script>

<style lang="scss" scoped>
	.course-bg {
		width: 92%;
		margin: auto;

		.course-information {
			width: 100%;
			height: 50px;
			background-color: #ffffff;
			.views {
				width: 12px;
				height: 12px;
			}
		}

		.course-content {
			width: 100%;
			background-color: #ffffff;
			box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
			border-radius: 3px 3px 0px 0px;
		}
	}
</style>
