<template>
	<!-- 添加板块 -->
	<div class="m-b-60">
		<div v-if="staff.length || groups.length">
			<div class="increase" v-if="staff.length">
				<list :list='staff' title="ta添加的员工" timeName="添加时间"></list>
			</div>
			<div class="increase" v-if="groups.length">
				<div class="m-t-23">
					<list :list="groups" title="ta添加的群组" :nickshow='true' nick="群主" timeName="添加时间"></list>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="flex justify-center" v-if="spinShow">
				<img src="@/assets/img/nullData.png" alt="" class="w-162 h-190">
			</div>
		</div>
	</div>
</template>

<script>
	import {getWorkGroup} from '../../api/code.js'
	
	import list from '../list.vue'

	export default {
		name: 'increase',
		props: {},
		components: {
			list
		},
		data() {
			return {
				// loading
				spinShow: false,
			}
		},
		computed: {
			// 添加员工列表
			staff() {
				return this.$store.state.increase.staff
			},
			// 添加群组列表
			groups() {
				return this.$store.state.increase.groups
			}
		},
		created() {
			if (this.$store.state.increase.staff.length == 0 && this.$store.state.increase.groups.length == 0) {
				this.getData()
				setTimeout(_ => {
					this.spinShow = true
				}, 1000)
			}
		},
		methods: {
			getData() {
				let data = {
					work_id: this.$store.state.workId,
				}
				// 添加用户
				getWorkGroup(data).then(res => {
					this.$store.commit('increase/getStaff', res.data.join)
					this.$store.commit('increase/getGroups', res.data.group)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.increase {
		width: 92%;
		margin: auto;
		background-color: #ffffff;
		box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1000);
		border-radius: 6px;
	}
</style>
