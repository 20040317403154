<template>
	<!-- 订单列表 -->
	<div>
		<div v-if="order.length">
			<mescroll-vue ref="mescroll" :down='mescrollDown' :up="mescrollUp" @init="mescrollInit" class="mescroll">
				<div class="m-t-10">
					<div class="order click-gesture" v-for="(order, orderIndex) in order" :key="orderIndex"
						@click="orderDetallsClick(order.id)">
						<div :class="orderIndex != 0 ? 'm-t-15' : ''">
							<div class="order-number flex justify-center">
								<div class="flex row items-center justify-between w-b-95 h-b-100">
									<div class="flex row items-center">
										<p class="dots"></p>
										<p class="color-666 size-12 m-l-5">订单号：{{ order.pay_osn }}</p>
									</div>
									<div class="">
										<p class="size-14"
											:class="order.status < 4 ? 'color-004' : order.status == 4 ? 'color-00B' : order.status == 5 ? 'color-333' : order.status == 9 ? 'color-F5C' : 'color-F34'">
											{{ order.status_name }}
										</p>
									</div>
								</div>
							</div>
							<div class="order-content flex justify-center m-t-10">
								<div class="w-b-95">
									<h1 class="size-14 color-333 weight-bold">{{ order.shop_name }}
									</h1>
									<div class="flex row justify-between m-t-10">
										<div class="flex row items-center">
											<img src="@/assets/img/renyuan.png" class="personnel" alt="人员">
											<p class="color-666 size-12 m-l-5">
												业务归属：{{ order.nickname }}
											</p>
										</div>
										<div>
											<p class="color-333 size-14 weight-bold">￥{{ order.price }}</p>
										</div>
									</div>
									<div class="m-t-15">
										<div class="line"></div>
									</div>
									<div class="m-t-15 flex row justify-between">
										<div>
											<p class="color-999 size-12">下单时间：{{ order.create_time }}</p>
										</div>
										<div class="material-border flex items-center justify-center" v-show="order.address_id">
											<p class="material color-004">含资料</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</mescroll-vue>
		</div>
		<div v-else>
			<div class="flex justify-center" v-if="spinShow">
				<img src="@/assets/img/nullData.png" alt="" class="w-162 h-190">
			</div>
		</div>
		<drawer title="订单详情" :display.sync="display" :width="drawerWidth" :height="drawerHeight" v-if="display">
			<orderDetails :nickname='nickName' :name='name' :phone='phone' :address='address' :books='books' :orderNumber='orderNumber'
				:price='price' :orderTime='orderTime' :paymentMethod='paymentMethod' :trackingnumber='trackingnumber' :status='status'
				:courierCompany='courierCompany'></orderDetails>
		</drawer>
	</div>
</template>

<script>
	import MescrollVue from 'mescroll.js/mescroll.vue'
	import drawer from '../../components/drawer.vue'
	import orderDetails from '../../components/order/orderDetails.vue'

	import {
		getOrderList,
		getOrderDetails
	} from '../../api/code.js'

	export default {
		name: 'order',
		props: {},
		components: {
			MescrollVue,
			drawer,
			orderDetails,
		},
		data() {
			return {
				// 《订单》
				nickName: '',
				// 上拉刷新
				mescroll: null, // mescroll实例对象
				mescrollDown: {
					auto: false,
					callback:function ( mescroll ) {
						mescroll.resetUpScroll();
					},
					autoShowLoading: true,
					afterLoading: function(mescroll) {
						return 2000
					},
					offset: 40
				},
				mescrollUp: {
					// 上拉回调
					callback: this.upCallback,
					auto: false,
					page: {
						num: 1,
						size: 10
					},
					noMoreSize: 5,
					toTop: {
						// 回到顶部按钮的图片路径,支持网络图
						src: 'http://www.mescroll.com/img/mescroll-totop.png'
					},
					lazyLoad: {
						use: true // 是否开启懒加载,默认false
					}
				},
				// 抽屉
				activeName: '1',
				display: false,
				drawerWidth: '100%',
				drawerHeight: '80%',
				// 《用户信息》
				// 订单号
				orderNumber: '-',
				// 订单状态
				status: 0,
				// 姓名
				name: '-',
				// 电话
				phone: '',
				// 地址
				address: '暂无物流地址',
				// 《书籍信息》
				books: [],
				// 书籍价格
				price: 0,
				// 《订单信息》
				// 下单时间
				orderTime: '',
				// 支付方式
				paymentMethod: '',
				// 快递单号
				trackingnumber: '-',
				// 快递公司
				courierCompany: '-',
				
				// loading
				spinShow: false
			}
		},
		computed: {
			order() {
				return this.$store.state.order.order
			}
		},
		created() {
			if (this.$store.state.order.order.length == 0) {
				this.getData()
				setTimeout(_ => {
					this.spinShow = true
				}, 1000)
			}
		},
		methods: {
			// mescroll组件初始化的回调,可获取到mescroll对象
			mescrollInit(mescroll) {
				this.mescroll = mescroll
			},
			// 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数(可配置),默认10
			upCallback(page, mescroll) {
				// 联网请求
				let data = {
					user_id: this.$store.state.userData.id,
					page: page.num,
					limit: 10
				}
				getOrderList(data).then((response) => {
					// 请求的列表数据
					let arr = response.data.list
					// 如果是第一页需手动置空列表
					if (page.num === 1) this.$store.state.order.order = []
					// 把请求到的数据添加到列表
					this.$store.state.order.order = this.$store.state.order.order.concat(arr)
					// 数据渲染成功后,隐藏下拉刷新的状态
					this.$nextTick(() => {
						mescroll.endSuccess(arr.length)
					})
				}).catch((e) => {
					// 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
					mescroll.endErr()
				})
			},
			
			getData() {
				let data = {
					user_id: this.$store.state.userData.id,
					page: 1,
					limit: 10
				}
				// 订单列表
				getOrderList(data).then(res => {
					this.$store.commit('order/getOrder', res.data.list)
					this.nickName = res.data.list.nickname
				})
			},

			orderDetallsClick(orderID) {
				this.display = !this.display
				// 订单详情
				getOrderDetails({
					order_id: orderID
				}).then(res => {
					let resOrder = res.data
					if (resOrder.pay_osn) {
						this.orderNumber = resOrder.pay_osn
					}
					this.status = resOrder.status
					if (resOrder.address) {
						this.name = resOrder.address.name
						this.phone = resOrder.address.mobile
						this.address = resOrder.address.address
					}
					this.books = resOrder.goodsInfo
					this.price = resOrder.price
					this.orderTime = resOrder.create_time
					this.paymentMethod = resOrder.pay_type
					if (resOrder.mail_trade) {
						this.trackingnumber = resOrder.mail_trade
					}
					if (resOrder.mail_com) {
						this.courierCompany = resOrder.mail_com
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mescroll {
		position: fixed;
		top: 230px;
		bottom: 0;
		height: auto;
		
		.order {
			width: 92%;
			margin: auto;
			background-color: #ffffff;
			box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
			border-radius: 3px 3px 0px 0px;
			margin-top: 5px;
			margin-bottom: 5px;
			padding-bottom: 15px;

			.order-number {
				width: 100%;
				height: 34px;
				background: #F8F8F8;
				border-radius: 3px 3px 0px 0px;

				.dots {
					width: 6px;
					height: 6px;
					background: #004EA2;
					border-radius: 3px 3px 3px 3px;
				}
			}

			.order-content {
				width: 100%;

				.personnel {
					width: 10.85px;
					height: 12.48px;
				}

				.line {
					height: 1px;
					background-color: #F6F6F6;
				}

				.material-border {
					width: 38px;
					height: 16px;
					border: 1px solid #004EA2;
					border-radius: 10px;

					.material {
						font-size: 9px;
						transform: scale(0.85);
					}
				}
			}
		}
	}
</style>
