<template>
	<!-- 物流详情 -->
	<div class="logistics-bg">
		<Spin size="large" fix v-if="spinShow"></Spin>
		<div class="m-t-30 m-b-60">
			<div class="flex row items-center color-333 size-12">
				<img :src="imgUrl" alt="" class="img">
				<p class="m-l-10">{{ courierCompany }}</p>
				<div class="flex row">
					<p class="m-l-10">{{ trackingnumber }}</p>
					<img v-if="trackingnumber != '-'" src="@/assets/img/copy-blue.png" class="copy m-l-15"
						alt="复制图标" v-clipboard='trackingnumber' v-clipboard:success="orderNumberSuccessHandler"
						v-clipboard:error="orderNumberErrorHandler">
				</div>
			</div>
			<Steps :current="7" direction="vertical" size="small" class="m-t-20">
				<Step :title="items.context" :content="items.time" icon="md-checkmark-circle"
					v-for="(items, idx) in logistics" :key="idx"></Step>
			</Steps>
		</div>
	</div>
</template>

<script>
	
	export default {
		name: 'logistics',
		props: {
			imgUrl: {
				type: String,
				default: ''
			},
			// 快递公司
			courierCompany: {
				type: String,
				default: ''
			},
			// 快递单号
			trackingnumber: {
				type: String,
			},
			logistics: {
				type: Array,
				default: []
			},
			spinShow: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				
			}
		},
		methods: {
			orderNumberSuccessHandler({value,event}) {
				this.$Message.success({
					content: '复制成功',
				})
			},
			
			orderNumberErrorHandler({value,event}) {
				this.$Message.error({
					content: '复制失败',
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.logistics-bg {
		width: 92%;
		margin: auto;

		.img {
			width: 24px;
			height: 24px;
		}
		
		.copy {
			width: 10.78px;
			height: 10.66px;
		}
	}
</style>
