<template>
	<!-- 学习列表 -->
	<div>
		<mescroll-vue ref="mescroll" :down='mescrollDown' :up="mescrollUp" @init="mescrollInit" class="mescroll">
			<div class="">
				<div class="study">
					<div class="study-time-bg">
						<div class="study-time flex row" v-for="(study, studyIndex) in study" :key="studyIndex">
							<div class="flex items-center w-b-50 h-b-100 bgcolor-004">
								<p class="color-666 size-12 m-l-20">{{ study.studyName }}</p>
							</div>
							<div class="flex items-center justify-center w-b-50 h-b-100">
								<p class="color-333 size-12">{{ study.studyTime }} {{ studyIndex < 6 ? "分钟" : "道" }}</p>
							</div>
						</div>
					</div>
					<div class="study-schedule flex justify-center" v-for="(item, scheduleIndex) in studySchedule"
						:key="scheduleIndex">
						<div class="study-schedule-content">
							<div class="m-t-15">
								<p class="color-333 size-14 weight-bold">{{ item.name }}</p>
							</div>
							<div class="m-t-15 m-b-20 flex row items-center justify-between">
								<p class="color-666 size-12">学习进度</p>
								<div class="progress-bar">
									<div class="progress-bar_inner"
										:style="{width: item.finish_rates > 100 ? '100%' : item.finish_rates + '%'}">
									</div>
								</div>
								<p class="color-666 size-12">{{ item.finish_rates > 100 ? 100 : item.finish_rates}}%</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</mescroll-vue>
	</div>
</template>

<script>
	import {
		getStudy
	} from '../../api/code.js'

	import MescrollVue from 'mescroll.js/mescroll.vue'

	export default {
		name: 'study',
		components: {
			MescrollVue
		},
		data() {
			return {
				// 《学习》
				// study: [],
				// 累计标题
				studyName: [
					'累计观看直播时长',
					'平均每日观看直播时长',
					'累计观看录播时长',
					'平均每日观看录播时长',
					'累计做题时长',
					'平均每日做题时长',
					'累计做题量',
					'平均每日做题量',
				],
				// 累计时间
				studyTime: [],
				// mescroll实例对象
				mescroll: null,
				mescrollDown: {
					auto: false,
					callback:function ( mescroll ) {
						mescroll.resetUpScroll();
					},
					autoShowLoading: true,
					afterLoading: function(mescroll) {
						return 2000
					},
					offset: 40
				},
				mescrollUp: {
					// 上拉回调
					callback: this.upCallback,
					auto: false,
					page: {
						num: 1,
						size: 5
					},
					noMoreSize: 5,
					toTop: {
						// 回到顶部按钮的图片路径,支持网络图
						src: 'http://www.mescroll.com/img/mescroll-totop.png'
					},
					lazyLoad: {
						use: true // 是否开启懒加载,默认false
					}
				},
			}
		},
		computed: {
			study() {
				return this.$store.state.study.study
			},
			// 学习进度
			studySchedule() {
				return this.$store.state.study.studySchedule
			}
		},
		created() {
			if (this.$store.state.study.studySchedule.length == 0) {
				this.getData()
			}
		},
		methods: {

			// mescroll组件初始化的回调,可获取到mescroll对象
			mescrollInit(mescroll) {
				this.mescroll = mescroll
			},
			// 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数(可配置),默认10
			upCallback(page, mescroll) {
				// 联网请求
				let data = {
					user_id: this.$store.state.userData.id,
					page: page.num,
					limit: 5
				}
				getStudy(data).then((response) => {
					// 请求的列表数据
					let arr = response.data.list
					// 如果是第一页需手动置空列表
					if (page.num === 1) this.$store.state.study.studySchedule = []
					// 把请求到的数据添加到列表
					this.$store.state.study.studySchedule = this.$store.state.study.studySchedule.concat(arr)
					// 数据渲染成功后,隐藏下拉刷新的状态
					this.$nextTick(() => {
						mescroll.endSuccess(arr.length)
					})
				}).catch((e) => {
					// 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
					mescroll.endErr()
				})
			},

			getData() {
				let data = {
					user_id: this.$store.state.userData.id,
					page: 1,
					limit: 5
				}
				// 学习板块
				getStudy(data).then(res => {
					let studyRes = res.data
					this.studyTime = studyRes.count
					// 遍历数组放入对象
					Object.keys(this.studyTime, this.studyName).forEach(key => {
						let item = {
							studyName: this.studyName[key],
							studyTime: this.studyTime[key]
						}
						this.$store.state.study.study.push(item)
					})
					this.$store.commit('study/getStudySchedule', studyRes.list)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mescroll {
		position: fixed;
		top: 230px;
		bottom: 0;
		height: auto;

		.study {
			width: 92%;
			margin: auto;

			.study-time-bg {
				width: 100%;
				box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
				border: 1px solid rgba(0, 78, 162, 0.1);

				.study-time {
					width: 100%;
					height: 40px;
					border-top: 1px solid rgba(0, 78, 162, 0.2);
					border-left: 1px solid rgba(0, 78, 162, 0.2);
				}
			}

			.study-schedule {
				width: 100%;
				background-color: #ffffff;
				box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
				margin-top: 15px;

				.study-schedule-content {
					width: 95%;

					.progress-bar {
						height: 4px;
						width: 70%;
						border-radius: 2px;
						background-color: #F8F7FC;

						.progress-bar_inner {
							height: 100%;
							width: 50%;
							background: #004EA2;
							border-radius: 2px;
						}
					}
				}
			}
		}
	}
</style>
