const state = {
	grouping: [],
}

const mutations = {
	getGrouping (state, val) {
		state.grouping = val
	}
}

export default {
	namespaced: true,
	state,
	mutations,
}
