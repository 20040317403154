<template>
  <!-- 个人信息 -->
  <div class="m-b-60">
    <div class="information">
      <!-- 基本信息 -->
      <div class="basic-bg">
        <longBlock title="基本信息"></longBlock>
        <div class="flex row">
          <div class="w-b-50">
            <div class="basic-content flex row size-12" v-for="(basic, index) in basicInformation" :key="index">
              <span class="color-666">{{ basic.title }}:</span>
              <span class="color-333 weight-bold m-l-20">{{ basic.name }}</span>
            </div>
          </div>
          <!--					<div class="w-b-50">-->
          <!--						<div class="basic-content flex row size-12" v-for="(basic, index) in basicInformation2" :key="index">-->
          <!--							<p class="color-666">{{ basic.title }}</p>-->
          <!--							<p class="color-333 weight-bold m-l-45">{{ basic.name }}</p>-->
          <!--						</div>-->
          <!--					</div>-->
        </div>
      </div>
      <!-- 课程信息 -->
      <!-- 		<div class="curriculum-bg m-t-15">
        <long-block title="课程信息"></long-block>
        <div v-if="courseInformation.length">
          <mescroll-vue ref="mescroll" :up="mescrollUp" @init="mescrollInit" class="mescroll">
            <action-track :list="courseInformation" title="下单时间" :interviewTimeShow="false"
              :frequencyShow="false" :priceShow="true" color="color:#999999" :marginButton="false"></action-track>
          </mescroll-vue>
        </div>
        <div v-else class="flex justify-center">
          <img src="@/assets/img/nullData.png" alt="" class="w-162 h-190">
        </div>
      </div> -->
      <!-- 协议信息 -->
      <!-- 			<div class="protocol-bg m-t-15">
        <long-block title="协议信息"></long-block>
        <action-track :list="protocolInformation" title="下单时间" access="有效期" :frequencyShow="false"
          color="color:#999999"></action-track>
      </div> -->
    </div>
  </div>
</template>

<script>
import longBlock from '../../components/longBlock.vue'
import actionTrack from '../../components/actionTrack.vue'
// import MescrollVue from 'mescroll.js/mescroll.vue'

import {
  getCourseList, getUserInfoList
} from '@/api/code'

export default {
  name: "information",
  props: {},
  components: {
    longBlock,
    actionTrack,
    // MescrollVue,
  },
  data() {
    return {
      // 《信息》
      // 基本信息
      basicInformation: [{
        title: '姓名',
        name: '-'
      }, {
        title: '院校',
        name: '-'
      }, {
        title: '学科',
        name: '-'
      }, {
        title: '城市',
        name: '-'
      }, {
        title: '电话',
        name: '-'
      }, {
        title: '专业',
        name: '-'
      },{
        title: '年龄',
        name: '-'
      }]
    }
  },
  created() {

    this.upCallback()
  },
  methods: {

    // mescroll组件初始化的回调,可获取到mescroll对象
    mescrollInit(mescroll) {
      this.mescroll = mescroll
    },
    // 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数(可配置),默认10
    upCallback() {
      // 联网请求
      let data = {
        user_id: this.$store.state.userData.id,
      }
      getUserInfoList(data).then((response) => {
        // 请求的列表数据
        let arr = response.data
        if (arr){
          // 把请求到的数据添加到列表
          this.basicInformation[0].name = arr['name']
          this.basicInformation[1].name = arr['college']
          this.basicInformation[2].name = arr['faculty']
          this.basicInformation[3].name = arr['intention_city']
          this.basicInformation[4].name = arr['mobile']
          this.basicInformation[5].name = arr['major']
          this.basicInformation[6].name = arr['age']
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mescroll {
  position: fixed;
  width: 91.5%;
  margin: auto;
  background-color: #ffffff;
  height: 60%;
}

.information {
  width: 92%;
  margin: auto;

  .basic-bg {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 78, 162, 0.1);

    .basic-content {
      margin: 15px 0 15px 28px;
    }
  }

  .curriculum-bg {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 78, 162, 0.1);
  }

  .protocol-bg {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 78, 162, 0.1);
  }
}
</style>
