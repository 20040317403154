import request from '@/utils/request'

// 获得userid
export function getPageUserId(data) {
  return request({
    url: '/wechat/work/getSignature',
    method: 'post',
    data
  })
}

// 获得user详情
export function getUserInfo(data) {
	return request({
		url: '/wechat/work/getClientInfo',
		method: 'post',
		data
	})
}

/**
 * @title 订单
 */

// 订单列表
export function getOrderList(data) {
	return request({
	  url: '/wechat/work/getOrderList',
	  method: 'post',
	  data
	})
}

// 订单详情
export function getOrderDetails(data) {
	return request({
	  url: '/wechat/work/getOrderInfo',
	  method: 'post',
	  data
	})
}

// 物流信息
export function getOrdermail(data) {
	return request({
	  url: '/wechat/work/getOrdermail',
	  method: 'post',
	  data
	})
}

/**
 * @title 添加用户
 */

export function getWorkGroup(data) {
	return request({
	  url: '/wechat/work/getWorkGroup',
	  method: 'post',
	  data
	})
}

/**
 * @title 分组
 */

// 已有分组列表
export function getGroupList(data) {
	return request({
	  url: '/wechat/userGroup/list',
	  method: 'post',
	  data
	})
}

// 分组权益
export function getGroupEquity(data) {
	return request({
	  url: '/wechat/userGroup/privilege',
	  method: 'post',
	  data
	})
}

// 添加分组列表
export function getAddGroup(data) {
	return request({
	  url: '/wechat/userGroup/group',
	  method: 'post',
	  data
	})
}

// 分组添加成功
export function getAddedSuccessfully(data) {
	return request({
	  url: '/wechat/userGroup/setGroup',
	  method: 'post',
	  data
	})
}

/**
 * @title 学习
 */

export function getStudy(data) {
	return request({
	  url: '/wechat/userCourse/study',
	  method: 'post',
	  data
	})
}


/**
 * @title 轨迹
 */

// 用户轨迹
export function getUserTrack(data) {
	return request({
	  url: '/wechat/userBehavior/list',
	  method: 'post',
	  data
	})
}

// 轨迹详情
export function getTrackDetails(data) {
	return request({
	  url: '/wechat/userBehavior/info',
	  method: 'post',
	  data
	})
}


/**
 * @title 信息
 */

export function getCourseList(data) {
	return request({
	  url: '/wechat/work/getCourseList',
	  method: 'post',
	  data
	})
}


/**
 * @title 备注
 */

export function getRemarkList(data) {
	return request({
	  url: '/wechat/userRemarks/list',
	  method: 'post',
	  data
	})
}

export function getAddRemark(data) {
	return request({
	  url: '/wechat/userRemarks/set',
	  method: 'post',
	  data
	})
}

export function getUserInfoList(data) {
	return request({
		url: '/wechat/userGroup/getUserInfo',
		method: 'post',
		data
	})
}

