<template>
	<!-- 已开通列表 -->
	<div class="m-b-80 h-b-100">
		<div class="group-bg">
			<div class="group-content m-t-15" v-for="(group, index) in groupDetails" :key="index">
				<div class="group-layout">
					<div>
						<span 
						class="tag w-30 h-16 size-10 text-center"
						:class="group.type == 1 ? 'courseStyle': group.type == 2 ? 'vipStyle' : ''">
							<span>{{ group.type == 1 ? '课程' : group.type == 2 ? 'VIP' : '' }}</span>
						</span>
						<span class="color-333 size-14 weight-bold m-l-5">{{ group.shop_name }}</span>
					</div>
					<div class="m-t-15">
						<span class="color-666 size-12">开通时间: {{ group.create_time }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'detail',
		props: {
			groupDetails: {
				type: Array,
				default: []
			}
		},
		data() {
			return {
				
			}
		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.courseStyle {
		color: #004EA2;
		background-color: rgba(0, 78, 162, 0.1);
	}

	.vipStyle {
		color: #F5CE00;
		background-color: rgba(245, 206, 0, 0.1);
	}

	.group-bg {
		width: 92%;
		margin: auto;

		.group-content {
			width: 100%;
			// height: 112px;
			background-color: #ffffff;
			box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
			border: 1px solid rgba(0, 78, 162, 0.1);

			.group-layout {
				margin: 16px 11px 20px 11px;
			}

			.tag {
				padding: 1px 5px;
				border-radius: 2px;
			}
		}
	}
</style>
