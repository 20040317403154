<template>
	<!-- 题库行动轨迹 -->
	<div>
		<mescroll-vue ref="mescroll" :down='mescrollDown' :up="mescrollUp" @init="mescrollInit" class="mescroll">
			<div class="m-t-10">
				<div class="line flex justify-center p-t-15" style="" v-for="(i, index) in list" :key="index"
					@click="tarckClick(i.id)">
					<div class="w-b-95">
						<div class="flex row items-center justify-between">
							<p class="color-333 size-14 weight-bold">{{ i.remarks }}</p>
						</div>
						<div class="flex row items-center justify-between m-t-10 color-666 size-12">
							<div>
								<p>课程停留总时长：{{ i.sum_duration }}秒</p>
							</div>
							<div>
								<p>访问次数：{{ i.sum_number }}次</p>
							</div>
						</div>
						<div class="flex row color-999 size-12 m-t-10">
							<p class="time">上次访问时间：{{ i.create_times }}</p>
						</div>
					</div>
				</div>
			</div>
		</mescroll-vue>
		<drawer title="题库记录详情" :display.sync="display" :width="drawerWidth" :height="drawerHeight">
			<questionBankDetails :duration='duration' :number='number' :course='course'></questionBankDetails>
		</drawer>
	</div>
</template>

<script>
	import drawer from '../drawer.vue'
	import questionBankDetails from '../track/questionBankDetails.vue'
	import MescrollVue from 'mescroll.js/mescroll.vue'

	import {
		getTrackDetails,
		getUserTrack
	} from "../../api/code.js"

	export default {
		name: 'actionTtack',
		props: {
			list: {
				type: Array,
				default: []
			},
			color: {
				type: String,
			},
		},
		components: {
			drawer,
			questionBankDetails,
			MescrollVue
		},
		data() {
			return {
				// 抽屉
				activeName: '1',
				display: false,
				drawerWidth: '100%',
				drawerHeight: '80%',
				// 题库详情参数
				duration: 0,
				number: 0,
				course: [],
				// mescroll实例对象
				mescroll: null,
				mescrollDown: {
					auto: false,
					callback:function ( mescroll ) {
						mescroll.resetUpScroll();
					},
					autoShowLoading: true,
					afterLoading: function(mescroll) {
						return 2000
					},
					offset: 40
				},
				mescrollUp: {
					// 上拉回调
					auto: false,
					callback: this.upCallback,
					page: {
						num: 1,
						size: 10
					},
					noMoreSize: 10,
					toTop: {
						// 回到顶部按钮的图片路径,支持网络图
						src: 'http://www.mescroll.com/img/mescroll-totop.png'
					},
					lazyLoad: {
						use: true // 是否开启懒加载,默认false
					}
				},
			}
		},
		methods: {
			// mescroll组件初始化的回调,可获取到mescroll对象
			mescrollInit(mescroll) {
				this.mescroll = mescroll
			},
			// 上拉回调 page = {num:1, size:10}; num:当前页 ,默认从1开始; size:每页数据条数(可配置),默认10
			upCallback(page, mescroll) {
				// 联网请求
				let data = {
					user_id: this.$store.state.userData.id,
					type: 2,
					page: page.num,
					limit: 10
				}
				getUserTrack(data).then((response) => {
					// 请求的列表数据
					let arr = response.data.list
					// 如果是第一页需手动置空列表
					if (page.num === 1) this.list = []
					// 把请求到的数据添加到列表
					this.list = this.list.concat(arr)
					// 数据渲染成功后,隐藏下拉刷新的状态
					this.$nextTick(() => {
						mescroll.endSuccess(arr.length)
					})
				}).catch((e) => {
					// 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
					mescroll.endErr()
				})
			},

			tarckClick(id) {
				this.display = !this.display
				let data = {
					user_id: this.$store.state.userData.id,
					log_id: id
				}
				getTrackDetails(data).then(res => {
					this.duration = res.data.sum_duration
					this.number = res.data.sum_number
					this.course = res.data.list
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.mescroll {
		position: fixed;
		// top: 345px;
		width: 92%;
		height: 50%;
		margin: auto;
		background-color: #ffffff;

		.line {
			border-bottom: 1px solid rgba(246, 246, 246, 1);
			padding-bottom: 15px;

			.rim {
				width: 38px;
				height: 16px;
				border-radius: 10px;

				.size {
					font-size: 9px;
					transform: scale(0.85);
				}
			}
		}
	}
</style>
