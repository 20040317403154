<template>
	<!-- 行动轨迹 -->
	<div :class="marginButton ? 'm-b-60' : ''">
		<div class="line flex justify-center p-t-15" style="" v-for="(i, index) in list" :key="index" @click="tarckClick">
			<div class="w-b-95">
				<div class="flex row items-center justify-between">
					<p class="color-333 size-14 weight-bold">{{ i.name }}</p>
					<p class="size-14" :style="i.modeStyle">{{ i.mode }}</p>
				</div>
				<div class="flex row items-center justify-between m-t-10 color-666 size-12" :style="color">
					<div>
						<p>{{ title }}：{{ i.create_time }}</p>
					</div>
					<div v-if="frequencyShow">
						<p>访问次数：{{ i.num }}次</p>
					</div>
					<div v-if="priceShow" class="flex row items-center">
						<p class="color-333 size-12">￥</p>
						<h1 class="color-333 size-14 weight-bold font-500">{{ i.price }}</h1>
					</div>
				</div>
				<div class="flex row justify-between color-999 size-12 m-t-10" v-if="interviewTimeShow">
					<p class="time">{{ access }}：{{ i.time }}</p>
					<div v-if="stateShow" class="rim flex items-center justify-center" :style="i.style">
						<p class="size" :style="i.size">{{ i.state }}</p>	
					</div>
				</div>
			</div>
		</div>
		<drawer title="课程记录详情" :display.sync="display" :width="drawerWidth" :height="drawerHeight">
			<coures></coures>
		</drawer>
	</div>
</template>

<script>
	import drawer from './drawer.vue'
	import coures from './track/courseDetails.vue'
	
	export default {
		name: 'actionTtack',
		props: {
			list: {
				type: Array,
				default: []
			},
			tagShow: {
				type: Boolean,
				default: false
			},
			color: {
				type: String,
			},
			frequencyShow: {
				type: Boolean,
				default: true
			},
			priceShow: {
				type: Boolean,
				default: false
			},
			title: {
				type: String,
				default: '课程停留总时长'
			},
			access: {
				type: String,
				default: '上次访问时间'
			},
			interviewTimeShow: {
				type: Boolean,
				default: true
			},
			stateShow: {
				type: Boolean,
				default: false
			},
			drawerShow: {
				type: Boolean,
				default: false
			},
			marginButton: {
				type: Boolean,
				default: true
			}
		},
		components: {
			drawer,
			coures
		},
		data() {
			return {
				// 抽屉
				activeName: '1',
				display: false,
				drawerWidth: '100%',
				drawerHeight: '80%',
			}
		},
		methods: {
			tarckClick() {
				if (this.drawerShow){
					this.display = !this.display
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.line {
		border-bottom: 1px solid rgba(246, 246, 246, 1);
		padding-bottom: 15px;
		.rim {
			width: 38px;
			height: 16px;
			border-radius: 10px;
			.size{
				font-size: 9px;
				transform: scale(0.85);
			}
		}
		
	}
</style>