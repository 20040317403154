<template>
	<!-- 订单详情 -->
	<div class="m-b-80">
		<div class="order-number flex justify-center">
			<div class="flex row justify-between items-center w-b-94">
				<p class="color-333 size-14">订单编号：</p>
				<div class="flex row items-center">
					<p class="color-666 size-14">{{ orderNumber }}</p>
					<img v-if="orderNumber != '-'" src="@/assets/img/copy-blue.png" class="copy m-l-15" alt="复制图标" v-clipboard='orderNumber'
						v-clipboard:success="orderNumberSuccessHandler" v-clipboard:error="orderNumberErrorHandler">
				</div>
			</div>
		</div>
		<div class="line"></div>
		<!-- 用户信息 -->
		<div v-if="books.length > 1">
			<div v-if="[2,3,5].includes(status)">
				<div class="user-info flex justify-center">
					<div class="flex row items-center w-b-94">
						<img src="@/assets/img/coordinate.png" class="coordinate" alt="">
						<div class="w-b-100 m-l-20">
							<div class="flex row items-center justify-between">
								<div class="flex row items-center color-333 size-16 weight-bold">
									<p>{{ name }}</p>
									<p class="m-l-20">{{ phone }}</p>
								</div>
								<div class="flex row items-center">
									<img :src="status == 5 ? '' : status == 2 ? require('@/assets/img/toBeDelivered.png') : status == 3 ? require('@/assets/img/transportation.png') : ''"
										class="transportation" alt="">
									<p class="state m-l-5"
										:class="status == 5 ? 'color-999' : status == 2 ? 'color-F34' : 'color-004'">
										{{ status == 5 ? '已签收' : status == 2 ? '待发货' : status == 3 ? '运输中' : ''}}
									</p>
								</div>
							</div>
							<div class="color-333 size-14 m-t-10">
								<p>{{ address }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="line"></div>
			</div>
		</div>
		<!-- 书籍资料 -->
		<div class="order-catalog flex justify-center">
			<div class="flex col w-b-94">
				<div v-for="(item, index) in books" :key="index">
					<div v-if="item.is_give == 1">
						<div class="color-333 size-14 weight-bold">
							<p>{{ item.shop_name }}</p>
						</div>
						<div class="flex row justify-between size-14 m-t-10">
							<p class="color-333">￥{{ item.price }}</p>
							<p class="color-666">x{{ item.number }}</p>
						</div>
					</div>
				</div>
				<div v-for="(items, key) in books" :key="'key' + key">
					<div class="flex row justify-between size-14 m-t-10" v-if="items.is_give == 2">
						<p class="color-ccc">{{ items.shop_name }}</p>
						<p>x1</p>
					</div>
				</div>
				<div class="line m-t-15 m-b-15"></div>
				<div class="flex row items-center justify-between">
					<div class="material-border flex items-center justify-center"
						:class="books.length > 1 ? 'material-004' : 'material-999'">
						<p class="material">{{ books.length > 1 ? '含资料' : '无资料' }}</p>
					</div>
					<div class="flex row items-center">
						<p class="color-333 size-14">合计：</p>
						<p class="color-F34 size-18 weight-bold">￥{{ price }}</p>
					</div>
				</div>
			</div>
		</div>
		<div class="line"></div>
		<!-- 订单信息 -->
		<div class="order-information flex justify-center">
			<div class="w-b-94">
				<h1 class="color-333 size-18 weight-bold m-b-5">订单信息</h1>
				<div class="flex row justify-between m-t-10">
					<p>下单时间：</p>
					<div class="flex row items-center">
						<p>{{ orderTime }}</p>
					</div>
				</div>
				<div class="flex row justify-between m-t-10">
					<p>支付方式：</p>
					<div class="flex row items-center">
						<p>{{ paymentMethod }}</p>
					</div>
				</div>
				<div class="flex row justify-between m-t-10">
					<p>业绩归属：</p>
					<div class="flex row items-center">
						<p>{{ nickname }}</p>
					</div>
				</div>
				<div v-if="books.length > 1">
					<div v-if="[2,3,5].includes(status)">
						<div class="flex row justify-between m-t-10">
							<p>快递单号：</p>
							<div class="flex row items-center">
								<p>{{ trackingnumber }}</p>
								<img v-show="trackingnumber != '-'" src="@/assets/img/copy-blue.png" class="copy m-l-15"
									alt="复制图标" v-clipboard='trackingnumber' v-clipboard:success="orderNumberSuccessHandler"
									v-clipboard:error="orderNumberErrorHandler">
							</div>
						</div>
						<div class="flex row justify-between m-t-10">
							<p>快递公司：</p>
							<div class="flex row items-center">
								<p>{{ courierCompany }}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="m-t-40 flex items-center justify-center"v-if="trackingnumber != '-'">
					<div class="logistics flex items-center justify-center" @click="logisticsClick">
						<p class="color-fff size-12">查看物流信息</p>
					</div>
				</div>
			</div>
		</div>
		<drawer title="物流信息" :display.sync="display" :width="drawerWidth" :height="drawerHeight">
			<logistics :imgUrl='imgUrl' :courierCompany='courierCompany' :trackingnumber='trackingnumber'
				:logistics='logistics' :spinShow='spinShow'></logistics>
		</drawer>
	</div>
</template>

<script>
	// 组件
	import drawer from '../../components/drawer.vue'
	import logistics from './logistics.vue'

	import {getOrdermail} from '../../api/code.js'

	export default {
		name: 'oderDetails',
		components: {
			drawer,
			logistics
		},
		props: {
			nickname: {
				type: String,
				default: ''
			},
			// 《用户信息》
			// 订单号
			orderNumber: {
				type: String,
				default: '-'
			},
			// 订单状态
			status: {
				type: Number,
				default: 0
			},
			// 姓名
			name: {
				type: String,
				default: ''
			},
			// 电话
			phone: {
				type: String,
				default: ''
			},
			// 地址
			address: {
				type: String,
				default: '暂无物流地址'
			},
			// 《书籍信息》
			books: {
				type: Array,
				default: []
			},
			// 书籍价格
			price: {
				type: Number,
				default: 0
			},
			// 《订单信息》
			// 下单时间
			orderTime: {
				type: String,
				default: ''
			},
			// 支付方式
			paymentMethod: {
				type: String,
				default: ''
			},
			// 快递单号
			trackingnumber: {
				type: String,
				default: '-'
			},
			// 快递公司
			courierCompany: {
				type: String,
				default: '-'
			},
		},
		data() {
			return {
				// 抽屉
				activeName: '1',
				display: false,
				drawerWidth: '100%',
				drawerHeight: '80%',
				imgUrl: '',
				// // 快递公司
				// courierCompany: '-',
				// // 快递单号
				// trackingnumber: '-',
				logistics: [],
				// loading
				spinShow: true
			}
		},
		methods: {
			orderNumberSuccessHandler({value,event}) {
				this.$Message.success({
					content: '复制成功',
				})
			},
			
			orderNumberErrorHandler({value,event}) {
				this.$Message.error({
					content: '复制失败',
				})
			},
			
			logisticsClick() {
				this.display = !this.display
				getOrdermail({
					mail_trade: this.trackingnumber
				}).then(res => {
					let logisticsRes = res.data
					this.logistics = logisticsRes.list
					this.imgUrl = logisticsRes.logo
					this.courierCompany = logisticsRes.mail_com
					this.trackingnumber = logisticsRes.mail_trade
					this.spinShow = false
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.line {
		width: 100%;
		height: 6px;
		background-color: #F2F2F2;
	}

	.material-999 {
		border: 1px solid #999999;
		color: #999999;
	}

	.material-004 {
		border: 1px solid #004EA2;
		color: #004EA2;
	}

	.order-number {
		width: 100%;
		height: 50px;

		.copy {
			width: 10.78px;
			height: 10.66px;
		}
	}

	.user-info {
		width: 100%;
		padding: 20px 0;

		.coordinate {
			width: 24px;
			height: 24px;
		}

		.transportation {
			width: 10.82px;
			height: 8.38px;
		}

		.state {
			font-size: 10px;
			transform: scale(0.84);
		}
	}

	.order-catalog {
		width: 100%;
		padding: 15px 0;

		.line {
			width: 100%;
			height: 1px;
			background-color: #F2F2F2;
		}

		.material-border {
			width: 38px;
			height: 16px;
			border-radius: 10px;

			.material {
				font-size: 9px;
				transform: scale(0.85);
			}
		}
	}

	.order-information {
		width: 100%;
		padding-top: 15px;

		.copy {
			width: 10.78px;
			height: 10.66px;
		}

		.logistics {
			width: 140px;
			height: 32px;
			background-color: #004EA2;
			border-radius: 16px;
		}
	}
</style>
