import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
	// {
	// 	path: '/groupDetails',s
	// 	name: 'groupDetails',
	// 	component: () => import('../views/grouping/groupDetails.vue')
	// },
	// {
	// 	path: '/addGroup',
	// 	name: 'addGroup',
	// 	component: () => import('../views/grouping/addGroup.vue')
	// },
	// {
	// 	path: '/order',
	// 	name: 'order',
	// 	component: () => import('../views/order/orderDetails.vue')
	// },
	// {
	// 	path: '/courseDetails',
	// 	name: 'courseDetails',
	// 	component: () => import('../views/track/courseDetails.vue')
	// },
	// {
	// 	path: '/addNotes',
	// 	name: 'addNotes',
	// 	component: () => import('../views/remark/addNotes.vue')
	// }
]

const router = new VueRouter({
  routes
})

export default router
