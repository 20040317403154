//导入axios
import axios from 'axios'
//使用element-ui Message做消息提醒
import {
  MessageBox,
  Message
} from 'element-ui'
//使用vuex管理Token
import store from '@/store'
//获取Token和移除Token的方法
import {
  getToken,
  removeToken
} from '@/utils/auth'

import wx from 'weixin-js-sdk';

//1.创建新的axios 实例
const service = axios.create({
  //公共接口（线下地址、测试地址、线上地址）
  // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: 'https://api.gzhls.cn/',
  //超时时间，单位是ms
  timeout: 10000
})

//2.请求拦截器
// service.interceptors.request.use(
//   config => {
//     //判断Token是否为true
//     if (store.getters.token) {
//       //配置请求头
//       config.headers['Access-Token'] = 'Bearer ' + getToken()
//     }
//     return config
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

 service.interceptors.request.use(config => {
    return config
  }, err => {
    console.log(err);
  })

//3.响应拦截器
service.interceptors.response.use(
    //使用Promise进行处理和响应
    response => {
      let axiosRes = response.data
      if (axiosRes.code >= 200 && axiosRes.code <= 205) {
        return axiosRes
      }
      //配置服务器返回的状态码及返回的提示信息
      if (axiosRes.code == 400) {
        Message({
          message: axiosRes.message,
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(new Error(axiosRes.message || '重复添加，无法进行操作'))
      }
      if (axiosRes.code == 403) {
        // 重新登录
        MessageBox.confirm('登录已失效，是否重新登录', '重新登录', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //vuex重置路由
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
    },
    error => {
      console.log('err' + error)
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(error)
    }
)

//4.导入文件
export default service
