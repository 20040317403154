const state = {
	study: [],
	studySchedule: []
}

const mutations = {
	getStudySchedule (state, val) {
		state.studySchedule = val
	},
	// getStudy(state, val) {
	// 	state.study = val
	// }
}

export default {
	namespaced: true,
	state,
	mutations,
}
